import React, { useState } from 'react';
import './App.css';


function App() {

  const [isNavListActive, setIsNavListActive] = useState(false);

  const toggleNavList = () => {
    setIsNavListActive(!isNavListActive);
  };

  return (
<>

<div className='container'>

<div className='containerhead'>
<div className="contLogo">
<img className="logo" src="LOGOPN.png" alt="BORDALASER" />
</div>
<div className="contLogo2">


<nav className="navbar">
            <div className="menu-icon" onClick={toggleNavList}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
            <ul className={`nav-list ${isNavListActive ? 'active' : ''}`}>
              <li><a href="/">Inicio</a></li>
              <li><a href="/">Quiénes Somos</a></li>
              <li><a href="/">Búsqueda</a></li>
              <li><a href="/">Servicios</a></li>
              <li><a href="#fin">Contacto</a></li>
            </ul>
          </nav>

</div>
</div>

<div className="cont-div0">
<div className="translucent-div0">
<h1>RECORDATORIOS</h1>
<h3>Motivos varios para tus 15 años, cumpleaños, bautizos, comuniones, bodas, eventos, entre otros.</h3>
<h4>¡El detalle perfecto para tus reuniones, regala un recuerdo personalizado!</h4>
</div>
</div>





<div>
  <br></br>




<div className="image-gallery">
    <div className="image-item">
      <img src="centro1.jpg" alt="Imagen 1"/>
      <div className="image-caption">Recordatorio tipo centro de mesa 13cm Altura</div>
    </div>
    <div className="image-item">
      <img src="centro2.jpg" alt="Imagen 2" />
      <div className="image-caption">Recordatorio tipo centro de mesa 13cm Altura</div>
    </div>
    <div className="image-item">
      <img src="centro3.jpg" alt="Imagen 3" />
      <div className="image-caption">Recordatorio tipo centro de mesa 13cm Altura</div>
    </div>
    <div className="image-item">
      <img src="topper1.jpg" alt="Imagen 4" />
      <div className="image-caption">Recordatorio tipo topper para flores 30cm Altura</div>
    </div>
    <div className="image-item">
      <img src="rec1.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec2.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec3.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec4.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec5.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec6.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec7.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec8.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec9.jpg" alt="Imagen 4" />
      <div className="image-caption">Portaretratos de multiples tamaños.</div>
    </div>
    <div className="image-item">
      <img src="rec10.jpg" alt="Imagen 4" />
      <div className="image-caption">Portaretratos de multiples tamaños.</div>
    </div>
    <div className="image-item">
      <img src="rec11.jpg" alt="Imagen 4" />
      <div className="image-caption">Portaretratos de multiples tamaños.</div>
    </div>
    <div className="image-item">
      <img src="rec12.jpg" alt="Imagen 4" />
      <div className="image-caption">Recordatorio tipo centro de mesa 13cm Altura</div>
    </div>
    <div className="image-item">
      <img src="rec13.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec14.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec15.jpg" alt="Imagen 4" />
      <div className="image-caption">Recordatorio tipo centro de mesa 13cm Altura</div>
    </div>
    <div className="image-item">
      <img src="rec16.jpg" alt="Imagen 4" />
      <div className="image-caption">topper´s para toda ocasión</div>
    </div>
    <div className="image-item">
      <img src="rec17.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec18.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec19.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="rec20.jpg" alt="Imagen 4" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
  </div>


  

</div>
<div className='espacios'>
</div>

<div className="cont-div">
<div className="translucent-div">
<h1>Empaques</h1>
<h3>Soluciones en empaques decorativos rígidos para tu negocio.</h3>
<h3>Manejamos para todas las ocasiones como día de la madre, padre, amor y amistad, desayunos, sorpresas, sorpresas, entre otros.</h3>
</div>
</div>

<div>




<div className="image-gallery">
    <div className="image-item">
      <img src="emp0.jpg" alt="Imagen 5" />
      <div className="image-caption">Porta botellas para 2 ó 3 botellas</div>
    </div>
    <div className="image-item">
      <img src="emp1.jpg" alt="Imagen 6" />
      <div className="image-caption">Porta botellas,cofres y mas</div>
    </div>
    <div className="image-item">
      <img src="emp2.jpg" alt="Imagen 7" />
      <div className="image-caption">Cofre redondo</div>
    </div>
    <div className="image-item">
      <img src="emp3.jpg" alt="Imagen 8" />
      <div className="image-caption">Cofre redondo</div>
    </div>
    <div className="image-item">
      <img src="emp4.jpg" alt="Imagen 9" />
      <div className="image-caption">Cofre de corazon</div>
    </div>
    <div className="image-item">
      <img src="emp5.jpg" alt="Imagen 10" />
      <div className="image-caption">Cofre de corazon</div>
    </div>
    <div className="image-item">
      <img src="emp6.jpg" alt="Imagen 10" />
      <div className="image-caption">Mini Repisas</div>
    </div>
    <div className="image-item">
      <img src="emp7.jpg" alt="Imagen 10" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="emp8.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja tipo Portalapices</div>
    </div>
    <div className="image-item">
      <img src="emp9.jpg" alt="Imagen 10" />
      <div className="image-caption">Cofres de 6x6x4Cm, 8x8x5Cm y mas...</div>
    </div>
    <div className="image-item">
      <img src="emp10.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja tipo Portalapices deportivas</div>
    </div>
    <div className="image-item">
      <img src="emp11.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja para desayunos sencillas</div>
    </div>
    <div className="image-item">
      <img src="emp12.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja tipo Portalapices</div>
    </div>
    <div className="image-item">
      <img src="emp13.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja tipo Portalapices</div>
    </div>
    <div className="image-item">
      <img src="emp14.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja para desayunos sencillas</div>
    </div>
    <div className="image-item">
      <img src="emp15.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja para desayunos con patas</div>
    </div>
    <div className="image-item">
      <img src="emp16.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja para desayunos con patas</div>
    </div>
    <div className="image-item">
      <img src="emp17.jpg" alt="Imagen 10" />
      <div className="image-caption">Caja para desayunos sencillas</div>
    </div>
  </div>


  

</div>



<div className='data'>

{/* <div className='whatsapp'>
  <img className="imgwhat" src="whatsapp.png" alt="whatsapp" />
  <h3>+57 314 747 98 74 </h3>
</div>
</div>
<div className='data'>
<div className='whatsapp'>
  <img className="imgwhat" src="whatsapp.png" alt="whatsapp" />
  <h3>+57 316 255 48 38</h3>
</div>
</div>
<div className='data'>
<div className='whatsapp'>
  <img className="imgwhat2" src="mail.png" alt="whatsapp" />
  <a href="mailto:contacto@bordalaser.com">contacto@bordalaser.com</a>
</div>
</div>
<div className='data'>
<div className='facebook'>
  <img className="imgwhat2" src="facebook.png" alt="whatsapp" />
  <a href="https://www.facebook.com/profile.php?id=100091567814966">Bordalaser</a>
</div> */}

</div>
<div className='data'>
{/* <div className='facebook'>
  <img className="imgwhat2" src="catalogo.png" alt="whatsapp" />
  <a href="catalogo.pdf">Descarga catalogo PDF</a>
</div> */}
</div>


</div>

<footer id="fin" class="footer">
    <div class="footer-content">
      <div class="contact-info">
        <div class="contact-item">
          <img src="whatsapp.png" alt="Teléfono" />
          <a href="https://wa.me/3162554838">+57 3162554838 - 3147479874</a>
        </div>
      </div>
    </div>
<div>
<div class="footer-content">
      <div class="contact-info">
        <div class="contact-item">
          <img src="facebook.png" alt="Facebook" />
          <a href="https://www.facebook.com/profile.php?id=100091567814966">Bordalaser</a>
        </div>
      </div>
    </div>
</div>
<div>

<div class="footer-content">
      <div class="contact-info">
        <div class="contact-item">
          <img src="mail.png" alt="Correo" />
          <a href="mailto:contacto@bordalaser.com">contacto@bordalaser.com</a>
        </div>
      </div>
    </div>
  
</div>
<div className='cali'></div>
<div className='cali'>Cali - Colombia</div>
<div className='cali'></div>
  </footer>
 


</>
  );
}

export default App;
